import styled from "@emotion/styled";
import CardCTA from "components/CardCTA";
import { colors } from "ui";
import { scrollToSection } from "utils/scrollIntoSection";

import usp1 from "../../assets/images/usp1.png";
import usp2 from "../../assets/images/usp2.png";
import usp3 from "../../assets/images/usp3.png";

const Wrapper = styled.div`
  margin-top: 150px;
  text-align: center;

  h1 {
    font-weight: 500;
    color: ${colors.blackText};
  }
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 25px;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
`;

const STEPS = [
  {
    img: usp1,
    title: "Redo inom 24 timmar",
    text: "Vi hjälper dig installera Sublyfe och skapar inlogg till dina frisörer",
    action: () => scrollToSection("interest-form"),
    actionText: "Kom igång",
  },
  {
    img: usp2,
    title: "Skräddarsydda abonnemang",
    text: "Välj produkter och skräddarsy ett abonnemang utifrån kundens behov",
    action: () => scrollToSection("interest-form"),
    actionText: "Kom igång",
  },
  {
    img: usp3,
    title: "Öka försäljningen",
    text: "Återta lojaliteten mot kunderna med prisvärda produkter",
    action: () => scrollToSection("interest-form"),
    actionText: "Kom igång",
  },
];

const GetStarted = () => (
  <Wrapper>
    <h1>Skaffa Sublyfe nu</h1>
    <CardWrapper>
      {STEPS.map(({ img, title, text, action, actionText }) => (
        <CardCTA
          key={title}
          img={img}
          title={title}
          text={text}
          button={{ text: actionText, action }}
        />
      ))}
    </CardWrapper>
  </Wrapper>
);

export default GetStarted;
