import styled from "@emotion/styled";
import Image from "next/image";
import { Button, colors } from "ui";
import { scrollToSection } from "utils/scrollIntoSection";

import productsImg from "../../assets/images/products.png";

const Wrapper = styled.div`
  display: flex;
  padding: 0px 20px;

  @media (max-width: 820px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ImgWrapper = styled.div`
  position: relative;
  width: 350px;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${colors.darkPink2};

  h2 {
    margin: 0;
    font-weight: 500;
    color: ${colors.blackText};
  }

  p {
    margin: 0;
    margin-top: 25px;
    max-width: 500px;
    letter-spacing: 1.2px;
    line-height: 22px;
    font-weight: 500;
    color: ${colors.blackText};
  }

  button {
    margin-top: 60px;
  }

  @media (max-width: 800px) {
    text-align: center;
    align-items: center;
  }
`;

const Hero = () => {
  return (
    <Wrapper>
      <Content>
        <h2>
          Öka försäljningen i din salong och återta lojaliteten mot kunderna.
        </h2>
        <p>
          Sublyfe erbjuder frisörsalonger en prenumerationstjänst som gör det
          möjligt att regelbundet erbjuda kunder deras mest omtyckta
          hårvårdsprodukter. Genom prenumerationstjänsten försäkras
          frisörsalongerna om att deras kunder alltid har tillgång till
          produkterna de älskar, samtidigt som frisörsalongerna ökar sin
          produktförsäljning.
        </p>
        <Button
          gid="hero-button-register-salon"
          aria-label="Registrera din salong"
          variant="contained"
          onClick={() => scrollToSection("interest-form")}
        >
          Registrera din salong
        </Button>
      </Content>
      <ImgWrapper>
        <Image
          src={productsImg}
          alt="productsImg"
          fill
          quality={100}
          style={{ objectFit: "cover" }}
        />
      </ImgWrapper>
    </Wrapper>
  );
};

export default Hero;
