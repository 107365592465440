import styled from "@emotion/styled";
import Image from "next/image";
import { Button, colors } from "ui";

const Wrapper = styled.div`
  width: 230px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  padding: 20px 0px;
  overflow: hidden;
  background: ${colors.white};
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  img {
    margin: 16px;
    margin-bottom: 0;
  }
`;

const CardContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: center;

  h2 {
    font-size: 14px;
    font-weight: 700;
  }

  p {
    font-size: 12px;
    margin-top: 0px;
  }

  button {
    margin-top: auto;
  }
`;

const BottomText = styled.p`
  && {
    margin-top: 10px;
    font-size: 16px;
    margin-bottom: 0;
    color: ${colors.darkPink2};
  }
`;

type Props = {
  img: any;
  title: string;
  text: string;
  button?: {
    text: string;
    action: () => void;
  };
  bottomText?: string;
};

const CardCTA = ({ img, title, text, button, bottomText }: Props) => {
  return (
    <Wrapper>
      <Image
        src={img}
        alt="img1"
        width={100}
        height={100}
        style={{ objectFit: "cover" }}
      />
      <CardContent>
        <h2>{title}</h2>
        <p>{text}</p>
        {button && (
          <Button
            aria-label={`${title} - ${button.text}`}
            size="small"
            variant="text"
            onClick={button.action}
          >
            {button.text}
          </Button>
        )}
        {bottomText && <BottomText>{bottomText}</BottomText>}
      </CardContent>
    </Wrapper>
  );
};

export default CardCTA;
