import GetStarted from "components/GetStarted";
import Hero from "components/Hero";
import Layout from "components/Layout";
import dynamic from "next/dynamic";
import { ReactElement } from "react";
import { Button, FAQ } from "ui";
import { scrollToSection } from "utils/scrollIntoSection";

import { NextPageWithLayout } from "./_app";

const DynamicUSP = dynamic(() => import("components/USP"), { ssr: false });
const DynamicPricing = dynamic(() => import("components/Pricing"), {
  ssr: false,
});
const DynamicTestimonials = dynamic(() => import("components/Testimonials"), {
  ssr: false,
});
const DynamicPartners = dynamic(() => import("components/Partners"), {
  ssr: false,
});
const DynamicInterestForm = dynamic(() => import("components/InterestForm"), {
  ssr: false,
});

const QUESTIONS = [
  {
    title: "Vad är Sublyfe och hur kan det hjälpa min salong?",
    text: "Sublyfe är en prenumerationstjänst för frisörsalonger som gör det möjligt att regelbundet erbjuda kunder deras mest omtyckta hårvårdsprodukter. Genom att erbjuda dina kunder en prenumeration kan du öka lojaliteten, uppmuntra återbesök och öka produktförsäljningen.",
  },
  {
    title: "Hur kan Sublyfe öka min salongs omsättning?",
    text: "Sublyfe hjälper till att skapa inkomstström för din salong genom att kunderna regelbundet prenumererar på produkter. Detta inte bara förbättrar din produktförsäljning, utan skapar också en mer stabil ekonomi för din verksamhet.",
  },
  {
    title: "Är det enkelt att komma igång med Sublyfe?",
    text: "Absolut! Vi erbjuder personlig support för att hjälpa dig att komma igång och få ut det mesta av Sublyfe. Allt du behöver göra är att fylla i formuläret för att boka en demo. Vi hjälper dig vidare därifrån.",
  },
  {
    title: "Hur kan Sublyfe förbättra mina kunders upplevelse?",
    text: "Med Sublyfe får dina kunder en enkel och bekväm lösning att alltid ha tillgång till sina favoritprodukter. Detta förbättrar inte bara deras upplevelse, utan bygger också starkare band mellan dig och dina kunder vilket leder till ökad lojalitet.",
  },
  {
    title: "Vilka produkter kan mina kunder prenumerera på?",
    text: "Med Sublyfe kan dina kunder prenumerera på alla produkter som din salong erbjuder. Vår tjänst är designad för att vara så flexibel som möjligt, vilket gör att vi kan anpassa oss efter din salongs unika produktutbud. Så oavsett vad dina kunder föredrar, kan Sublyfe stödja dig i att erbjuda dem en smidig och praktisk prenumerationstjänst.",
  },
];

const Index: NextPageWithLayout = () => (
  <>
    <Hero />
    <GetStarted />
    <DynamicUSP
      title="Stärk bandet mellan din salong och dina kunder"
      img={
        <svg
          id="10015.io"
          viewBox="0 0 480 480"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <clipPath id="blob">
              <path d="M409,304Q392,368,333,399.5Q274,431,205,426Q136,421,102,362Q68,303,67,240Q66,177,97.5,112Q129,47,203.5,37Q278,27,344.5,61.5Q411,96,418.5,168Q426,240,409,304Z" />
            </clipPath>
          </defs>
          <image
            x="0"
            y="0"
            width="100%"
            height="100%"
            clipPath="url(#blob)"
            xlinkHref={"/hair-dryer.jpg"}
            preserveAspectRatio="xMidYMid slice"
          ></image>
        </svg>
      }
      content={
        <>
          <p>
            Med Sublyfe kan din frisörsalong erbjuda dina kunder en möjlighet
            att prenumerera på sina mest omtyckta hårvårdsprodukter.
          </p>
          <p>
            Sublyfe gör det enklare än någonsin att garantera dina kunder
            tillgång till deras önskade produkter och ökar din salongs
            produktförsäljning.
          </p>
          <p>
            Vi på Sublyfe arbetar hand i hand med din salong för att skapa en
            överlägsen kundupplevelse och driva tillväxten av din verksamhet.
          </p>
          <Button
            gid="connection-usp-button-get-started"
            aria-label="kom igång idag"
            onClick={() => scrollToSection("interest-form")}
          >
            Kom igång idag
          </Button>
        </>
      }
    />
    <DynamicPricing />
    <DynamicTestimonials />
    <DynamicPartners />
    <DynamicInterestForm />
    <FAQ questions={QUESTIONS} />
  </>
);

Index.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
};

export default Index;
